import { FC, MouseEvent, useCallback, useState } from 'react';
import {
  Button,
  Col,
  Icon,
  Input,
  Row,
  Typography
} from '@shared_medialab/ui_components';
import { useColors } from 'hooks';
import { useModalStateContext, useUIDispatchContext } from 'providers/UI';
import { useTranslation } from 'react-i18next';
import { ModalKeys } from 'providers/UI/types';
import { theme } from '@shared_medialab/ui_components/build/styled/default';

import {
  StyledConfirmModal,
  StyledSuccessContainer,
  StyledDangerContainer,
  StyledModalWrapper,
  StyledMessage,
  StyledModalFooter,
  StyledWarningContainer
} from './styled';
import { ConfirmModalTypes, IConfirmProps } from './types';
import { CONFIRM_BUTTON_MODES } from './constants';
import MessageReason from './components/MessageReason';

const { Text } = Typography;

const ConfirmModal: FC<IConfirmProps> = () => {
  //state
  const [massageColor, setMassageColor] = useState(false);
  const [value, setValue] = useState('');
  // translations
  const { t } = useTranslation('common');
  // ui
  const { confirm } = useModalStateContext();
  const { toggleModal } = useUIDispatchContext();
  // hooks
  const colors = useColors();
  const onOutsideClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.preventDefault();

      if (e.target === e.currentTarget) {
        toggleModal(ModalKeys.confirm, false);
      }
    },
    [toggleModal]
  );

  const onClickConfirm = () => {
    if (!confirm.params?.reason) {
      toggleModal(ModalKeys.confirm, false);
      confirm.params?.onConfirm?.();
    } else if (value) {
      setValue('');
      setMassageColor(false);
      toggleModal(ModalKeys.confirm, false);
      confirm.params?.onConfirmWithReason?.(value);
    } else {
      setMassageColor(true);
    }
  };

  const type = confirm.params?.type || ConfirmModalTypes.confirm;

  return (
    <StyledConfirmModal isVisible={confirm.visible} onClick={onOutsideClick}>
      <StyledModalWrapper>
        <Text
          className="flex-display text-center relative-position ph--32 pt--32"
          bold
          level={11}
          color={confirm.params?.titleColor || theme.colors.blue[8]}
        >
          {confirm.params?.title}
        </Text>
        <div className="flex-display flex-justify-center p--24">
          {confirm.params?.reason ? (
            <div className="flex-display flex-direction-column gap--24 flex-align-items-center">
              {confirm.params?.titlePosition ? (
                <>
                  <Input
                    value={value}
                    onChange={e => setValue(e.target.value)}
                    cornerRadius="smooth"
                    placeholder={confirm.params?.placeholder || ''}
                    labelAppearance="none"
                  />
                  <MessageReason massageColor={massageColor}>
                    {confirm.params?.message}
                  </MessageReason>
                </>
              ) : (
                <>
                  <MessageReason massageColor={massageColor}>
                    {confirm.params?.message}
                  </MessageReason>
                  <Input
                    value={value}
                    onChange={e => setValue(e.target.value)}
                    cornerRadius="smooth"
                    placeholder={confirm.params?.placeholder || ''}
                  />
                </>
              )}
            </div>
          ) : (
            <div className="flex-display flex-align-items-center">
              {confirm.params?.type === ConfirmModalTypes.confirm ? (
                <StyledSuccessContainer>
                  <Icon type="success" size={80} color={colors.success} />
                </StyledSuccessContainer>
              ) : type === ConfirmModalTypes.warning ? (
                <StyledWarningContainer>
                  <Icon type="info" size={48} color={colors.primary} />
                </StyledWarningContainer>
              ) : type === ConfirmModalTypes.danger ? (
                <StyledDangerContainer>
                  <Icon type="trash" size={48} color={colors.danger} />
                </StyledDangerContainer>
              ) : (
                <></>
              )}
              <StyledMessage>{confirm.params?.message}</StyledMessage>
            </div>
          )}
        </div>
        <StyledModalFooter>
          <Row gutter={[4, 4]} align="center" justify="space-between">
            <Col span={6}>
              <Button
                color="contrastNew"
                appearance="default"
                cornerRadius="smooth"
                flexibility="full-width"
                size="big"
                onClick={() => toggleModal(ModalKeys.confirm, false)}
              >
                {confirm.params?.cancelText || t('no')}
              </Button>
            </Col>
            <Col span={6}>
              <Button
                flexibility="full-width"
                appearance="default"
                cornerRadius="smooth"
                color={CONFIRM_BUTTON_MODES[type]}
                size="big"
                loading={confirm.params?.loading}
                onClick={onClickConfirm}
              >
                {confirm.params?.confirmText || t('ok')}
              </Button>
            </Col>
          </Row>
        </StyledModalFooter>
      </StyledModalWrapper>
    </StyledConfirmModal>
  );
};

export default ConfirmModal;
