import { FC, lazy, Suspense } from 'react';
import {
  Outlet,
  Navigate,
  RouteObject,
  RouterProvider,
  createBrowserRouter
} from 'react-router-dom';
import { ProductStatuses } from 'providers/Projects/types';
import AutoBookingProvider from 'providers/AutoBooking';
import ChoosePackageProvider from 'providers/ChoosePackage';
import AuthProvider from 'providers/Auth';
import ProjectProvider from 'providers/Projects';
import UIProvider from 'providers/UI';
import EventsProvider from 'providers/Events';
import FiltersProvider from 'providers/FilterProvider';
import FiltersProviderBuyer from 'providers/Filters';
import * as permissionConstants from 'constants/permissions';
import { Loading } from '@shared_medialab/ui_components';

import { ModalLayout, PrivateLayout } from 'components/layout';
import { StatusState } from 'components/shared';
import Private from 'components/shared/Private';

// pages

const Events = lazy(() => import('pages/Events/Wrapper'));
const NotFound = lazy(() => import('pages/NotFound'));
const EventsContent = lazy(() =>
  import('pages/Events/components').then(module => ({
    default: module.EventsContent
  }))
);

const Reports = lazy(() => import('pages/Reports'));
const AutoBookingDetails = lazy(() => import('pages/AutoBookingDetails'));
const Users = lazy(() => import('pages/UserManagement'));

const routes: RouteObject[] = [
  {
    path: '',
    element: (
      <ProjectProvider>
        <AuthProvider>
          <UIProvider>
            <FiltersProviderBuyer>
              <EventsProvider>
                <FiltersProvider>
                  <Outlet />
                  <ModalLayout />
                </FiltersProvider>
              </EventsProvider>
            </FiltersProviderBuyer>
          </UIProvider>
        </AuthProvider>
      </ProjectProvider>
    ),
    children: [
      {
        path: '/streaming',
        element: <PrivateLayout />,
        children: [
          {
            path: 'notFound',
            element: (
              <Suspense fallback={<Loading />}>
                <NotFound />
              </Suspense>
            )
          },
          {
            path: 'events',
            element: (
              <Suspense fallback={<Loading />}>
                <Events />
              </Suspense>
            ),
            children: [
              {
                path: ':type',
                element: (
                  <Suspense fallback={<Loading />}>
                    <EventsContent />
                  </Suspense>
                )
              }
            ]
          },
          {
            path: '/streaming/reporting',
            element: (
              <Suspense fallback={<Loading />}>
                <Reports />
              </Suspense>
            )
          },
          {
            path: 'users',
            element: (
              <Private
                routeKey={permissionConstants.VIEW}
                routeId={permissionConstants.BUYER_ROUTES_IDS.buyer_users}
              >
                <StatusState
                  states={{
                    product: [
                      ProductStatuses.active,
                      ProductStatuses.pending,
                      ProductStatuses.integration,
                      ProductStatuses.trial
                    ]
                  }}
                  component={
                    <Suspense fallback={<Loading />}>
                      <Users />
                    </Suspense>
                  }
                />
              </Private>
            )
          },
          {
            path: '',
            element: <Navigate to="/events/list" />
          },
          {
            path: '*',
            element: (
              <Suspense fallback={<Loading />}>
                <NotFound />
              </Suspense>
            )
          }
        ]
      },
      {
        path: 'autobooking/details',
        element: (
          <ChoosePackageProvider>
            <AutoBookingProvider>
              <Outlet />
            </AutoBookingProvider>
          </ChoosePackageProvider>
        ),
        children: [
          {
            path: '',
            element: (
              <Suspense fallback={<Loading />}>
                <AutoBookingDetails />
              </Suspense>
            )
          },
          {
            path: ':id',
            element: (
              <Suspense fallback={<Loading />}>
                <AutoBookingDetails />
              </Suspense>
            )
          }
        ]
      },
      {
        path: '',
        element: <Navigate to="streaming/events/list" />
      }
    ]
  }
];

const AppRouter: FC = () => {
  return <RouterProvider router={createBrowserRouter(routes)} />;
};

export default AppRouter;
