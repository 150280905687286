import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Select } from '@shared_medialab/ui_components';
import {
  useEventsCartLoadingStateContext,
  useEventsDispatchContext,
  useSelectedCountriesStateContext
} from 'providers/Events';
import { money } from 'helpers/money';
import { useTranslation } from 'react-i18next';
import './index.css';
import { useMutation } from '@apollo/client';
import { UPDATE_CART } from 'gql/cart/mutations';
import { useProjectStateContext } from 'providers/Projects';
import { useUIDispatchContext } from 'providers/UI';
import { ModalKeys } from 'providers/UI/types';
import { UpdateCart } from 'gql/cart/types/UpdateCart';
import { toast } from 'react-toastify';
import client from 'apolloClient';
import { GET_CART } from 'gql/cart/queries';

import { ICountrySelectProps } from './types';

const CountrySelectNew: FC<ICountrySelectProps> = ({
  countries,
  matchId,
  disable,
  initialCountries,
  shouldApplyToAll,
  className
}) => {
  // states
  const [search, setSearch] = useState('');
  const cartLoading = useEventsCartLoadingStateContext();

  const [updatedSelectedCountries, setUpdatedSelectedCountries] = useState<
    string[]
  >([]);

  const [updatedInitialCountries, setUpdatedInitialCountries] = useState<
    string[]
  >([]);

  // translations
  const { t } = useTranslation('cart');
  // context
  const { toggleModal } = useUIDispatchContext();
  const cart = useSelectedCountriesStateContext();
  const { selectedProject } = useProjectStateContext();
  const {
    selectMultipleCountries,
    applyCartItemCountriesToAll,
    setCartLoading
  } = useEventsDispatchContext();

  const handleRefetch = useCallback(async () => {
    setCartLoading(true);

    try {
      await client.refetchQueries({ include: [GET_CART] });
    } catch (error) {
      console.error('Error refetching:', error);
    } finally {
      setCartLoading(false);
    }
  }, [setCartLoading]);

  // graphql
  const [updateCart, { loading }] = useMutation<UpdateCart>(UPDATE_CART, {
    onError(error) {
      toast.error(error.message);
    },
    async onCompleted() {
      const areCountriesDifferent =
        [...updatedSelectedCountries].sort().toString() !==
        [...updatedInitialCountries].sort().toString();

      if (shouldApplyToAll && areCountriesDifferent) {
        await handleRefetch();
      }
    }
  });

  useEffect(() => {
    setUpdatedInitialCountries(initialCountries);
  }, [initialCountries]);

  const selectedCountryIds = useMemo(
    () => cart[matchId]?.map(elem => elem.id || '') || [],
    [cart, matchId]
  );

  useEffect(() => {
    setUpdatedSelectedCountries(selectedCountryIds);
  }, [selectedCountryIds]);

  const handleChange = useCallback(
    (country: string[]) => {
      const cardItem = countries?.filter(el => country.includes(el.id || ''));
      selectMultipleCountries(matchId, cardItem || []);
    },
    [countries, matchId, selectMultipleCountries]
  );

  const bookedCountryIds = useMemo(
    () =>
      countries?.filter(country => country?.booked).map(el => el?.id || '') ||
      [],
    [countries]
  );

  const searchedCountries = useMemo(
    () =>
      countries?.filter(option =>
        option.country?.toLowerCase().includes(search.toLowerCase())
      ) || [],
    [countries, search]
  );

  const onUpdateCart = (all = false) => {
    return updateCart({
      variables: {
        id: matchId,
        ...(selectedProject?.id ? { organizationId: selectedProject.id } : {}),
        input: {
          countries: selectedCountryIds,
          all: all
        }
      }
    });
  };

  const onClose = useCallback(() => {
    if (!loading) {
      onUpdateCart()
        .then(() => {
          setCartLoading(false);
        })
        .catch(error => {
          console.error('Cart update failed:', error);
          setCartLoading(false);
        });
    }

    const areCountriesDifferent =
      [...updatedSelectedCountries].sort().toString() !==
      [...updatedInitialCountries].sort().toString();

    if (shouldApplyToAll && areCountriesDifferent) {
      toggleModal(ModalKeys.confirm, true, {
        title: t('successfully_saved'),
        message: t('want_update'),
        onConfirm: async () => {
          try {
            setCartLoading(true);

            const data = await onUpdateCart(true);

            setUpdatedSelectedCountries(selectedCountryIds);
            setUpdatedInitialCountries(
              data?.data?.updateCart?.data?.matches?.find(
                el => el.match.id === matchId
              )?.countries || []
            );

            applyCartItemCountriesToAll(matchId);
          } catch (error) {
            console.error('Apply to all failed:', error);
          } finally {
            setCartLoading(false);
          }
        }
      });
    } else {
      setSearch('');
      toggleModal(ModalKeys.confirm, false);
      setCartLoading(false); // Ensure loading is turned off
    }
  }, [
    loading,
    updatedSelectedCountries,
    updatedInitialCountries,
    shouldApplyToAll,
    onUpdateCart,
    setCartLoading,
    toggleModal,
    t,
    selectedCountryIds,
    applyCartItemCountriesToAll,
    matchId
  ]);

  const availableCountriesTotal = useMemo(() => {
    return searchedCountries.filter(country => !country?.booked)?.length || 0;
  }, [searchedCountries]);

  return (
    <Select
      className={className}
      total={availableCountriesTotal}
      mode="multiple"
      onClose={onClose}
      placeholder={t('choose_countries')}
      options={
        searchedCountries?.map(item => ({
          label: `${item.country} ${money(`${item.price}`)}`,
          value: item.id || ''
        })) || []
      }
      onChange={handleChange}
      onSearch={e => setSearch(e.target.value)}
      searchValue={search}
      value={selectedCountryIds || ''}
      cornerRadius="smooth"
      itemDisable={bookedCountryIds}
      //TODO: disable(OutOfYourPackage), this should not be received here, must be delete
      disabled={disable || cartLoading}
    />
  );
};

export default memo(CountrySelectNew);
